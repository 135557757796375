import { svgIcons } from '../../assets/svgIcons';

export const socialLinks = [
  {
    icon: svgIcons.discord,
    href: 'https://discord.com/invite/2jeVHncdH3',
  },
  {
    icon: svgIcons.telegram,
    href: 'https://t.me/brightfunded',
  },
  {
    icon: svgIcons.instagram,
    href: 'https://www.instagram.com/brightfunded',
  },
  {
    icon: svgIcons.youtube,
    href: 'https://www.youtube.com/@BrightFunded',
  },
  {
    icon: svgIcons.twitter,
    href: 'https://twitter.com/BrightFunded',
  },
  {
    icon: svgIcons.linkedIn,
    href: 'https://www.linkedin.com/company/brightfunded/',
  },
];
export const burgerSocialLinks = [
  socialLinks[0],
  socialLinks[1],
  socialLinks[2],
  socialLinks[4],
];
