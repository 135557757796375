export const headerLinks = [
    {
        label: "Trade2Earn",
        to: "/trade#trade-top",
    },
    {
        label: "FAQ",
        to: "/faq#faq-top",
    },
    {
        label: "Affiliate Program",
        to: "/affiliate#top",
    },
]

